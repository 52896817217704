import { PriceQuotationsStats, StatsHeaderWidget } from '../../models';

export interface State {
  data: PriceQuotationsStats;
  dataWidget: StatsHeaderWidget[];
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  data: null,
  dataWidget: null,
  isLoading: false,
  error: null,
};
