import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PriceQuotationsStatsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class PriceQuotationsStatsStoreEffects {
  constructor(
    private dataService: PriceQuotationsStatsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties }) =>
        this.dataService.load(properties).pipe(
          map(({ data }: any) => fromActions.loadSuccess({ stats: data })),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadStatsWidgetRequest),
      switchMap(({ data }) =>
        this.dataService.loadWidget(data).pipe(
          map((response: any) => fromActions.loadStatsWidgetSuccess(response)),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadStatsWidgetFailure(error));
          }),
        ),
      ),
    ),
  );
}
