import {
  PriceQuotationsStats,
  PriceQuotationsStatsWidget,
  PriceQuotationsStatsWidgetRequest,
} from '@app/models';
import { createAction, props } from '@ngrx/store';

export const loadRequest = createAction(
  '[Price Quotations Stats] Load Request',
  props<{ properties: number[] }>(),
);

export const loadSuccess = createAction(
  '[Price Quotations Stats] Load Success',
  props<{ stats: PriceQuotationsStats }>(),
);

export const loadFailure = createAction(
  '[Price Quotations Stats] Load Failure',
  props<{ error: any }>(),
);

export const loadStatsWidgetRequest = createAction(
  '[Price Quotations Stats] Load Stats Widget Request',
  props<{ data: PriceQuotationsStatsWidgetRequest }>(),
);

export const loadStatsWidgetSuccess = createAction(
  '[Price Quotations Stats] Load Stats Widget Success',
  props<{ data: PriceQuotationsStatsWidget }>(),
);

export const loadStatsWidgetFailure = createAction(
  '[Price Quotations Stats] Load Stats Widget Failure',
  props<{ error: any }>(),
);

export const reseState = createAction('[Price Quotations Stats] Reset State');
