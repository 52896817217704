import { Action, createReducer, on } from '@ngrx/store';
import { upperFirst } from 'lodash';
import moment from 'moment';

import { floatRound } from '../../helpers/float-round';
import {
  PriceQuotationsStatsWidgetData,
  StatsHeaderWidget,
} from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { stats }) => {
    let all = 0;
    Object.keys(stats).forEach((key) => {
      all += stats[key];
    });
    return {
      ...state,
      data: { ...stats, all },
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadStatsWidgetRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadStatsWidgetSuccess, (state, { data }) => {
    const dataWidget: StatsHeaderWidget[] = [...(data?.months || [])]
      .reverse()
      .map((month) => {
        return createWidgetDataStats(month, 'month');
      });
    if (data?.year) {
      dataWidget.push(createWidgetDataStats(data.year[0], 'year'));
    }

    return {
      ...state,
      dataWidget,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.loadStatsWidgetFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.reseState, () => fromState.initialState),
);

export function priceQuotationsStatsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function createWidgetDataStats(
  data: PriceQuotationsStatsWidgetData,
  type: 'month' | 'year',
): StatsHeaderWidget {
  const year = moment(data.index, 'YYYY-MM').format('YYYY');
  const title = {
    key: `revenue_${type}`,
    params: {
      month_name: upperFirst(moment(data.index, 'YYYY-MM').format('MMMM')),
      year,
    },
  };
  const prefixIcon =
    data.amount.diff_percent >= 0
      ? 'fas fa-arrow-alt-up color--green'
      : 'fas fa-arrow-alt-down color--red';

  let subtitle: any = {
    key: `year_compare_no_value`,
    params: {
      year: +year - 1,
    },
  };
  if (data.amount.previous > 0) {
    subtitle = {
      key: `revenue_${type}_compare`,
      params: {
        month_name: upperFirst(moment(data.index, 'YYYY-MM').format('MMM')),
        year: +year - 1,
        percent_value: floatRound(data.amount.diff_percent),
      },
      prefixIcon,
    };
  }

  return {
    value: data.amount.current,
    count: data.reservations.current,
    title,
    subtitle,
    valueType: 'currency',
  };
}
